import React from 'react'
import './footer.css'
export const Footer = () => {
  return (
    <section id="footer">
      <h2>Mustakim</h2>
      <div className="container container__footer">
        <h4>Made with ❤  Nagori Mustakim</h4>
      </div>
      <div className="right">
        <h5>© Nagori Mustakim. All rigths reserved</h5>
      </div>
    </section>
  )
}
